import React from 'react';

import { IconSvgProps } from '../iconProps';

const Icon = ({ title, ...props }: IconSvgProps) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      {title && <title>{title}</title>}
      <circle cx="12" cy="12" r="10" fill="#85858A" />
      <path
        d="M9.52546 9.83497C9.56705 9.74019 9.64318 9.6639 9.73907 9.62092L16.1187 6.76153C16.4667 6.60556 16.8318 6.94335 16.6917 7.29168L14.1931 13.5053C14.1527 13.6058 14.0738 13.687 13.9732 13.7317L7.3249 16.6838C6.97124 16.8409 6.60507 16.4903 6.75822 16.1413L9.52546 9.83497Z"
        fill="#D9D9D9"
      />
      <circle
        cx="12.0635"
        cy="11.416"
        r="1.11111"
        transform="rotate(15 12.0635 11.416)"
        fill="#85858A"
      />
    </svg>
  );
};

export const IOSSafari = React.memo(Icon);

/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const X = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M4.614 3.024 3.02 4.616 10.406 12l-7.384 7.384 1.593 1.593 7.384-7.385 7.385 7.385 1.592-1.592L13.59 12l7.386-7.386-1.593-1.592L12 10.408 4.614 3.024Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(X);
export default Memo;

/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const CircleExclamation = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M11.999 2.996a9 9 0 1 0 0 18 9 9 0 0 0 0-18Zm0 11.552a1.252 1.252 0 1 1 0 2.505 1.252 1.252 0 0 1 0-2.505Zm.938-1.498H11.06l-.375-6.112h2.628l-.375 6.112Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(CircleExclamation);
export default Memo;

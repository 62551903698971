import React from 'react';

import { IconSvgProps } from '../iconProps';

const Icon = ({ title, ...props }: IconSvgProps) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      {title && <title>{title}</title>}
      <path
        d="M1 9C1 7.89543 1.89543 7 3 7H21C22.1046 7 23 7.89543 23 9V21C23 22.1046 22.1046 23 21 23H3C1.89543 23 1 22.1046 1 21V9Z"
        fill="#1B1F23"
        fillOpacity="0.4"
      />
      <path
        d="M4 6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6H4Z"
        fill="#1B1F23"
        fillOpacity="0.4"
      />
      <path
        d="M7 3C7 1.89543 7.89543 1 9 1H15C16.1046 1 17 1.89543 17 3H7Z"
        fill="#1B1F23"
        fillOpacity="0.4"
      />
    </svg>
  );
};

export const IOSCarousel = React.memo(Icon);

/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const ReferralURL = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M4.504 5.252h15v10.5h-15v-10.5Zm9.49 4.66L11.581 7.5h1.93l3.1 3.1-3.1 3.1h-1.93l2.48-2.411H8V9.91h5.993Z"
      clipRule="evenodd"
    />
    <path d="M21.936 17.252H2.063v1.502h19.874v-1.502Z" />
  </svg>
);

const Memo = memo(ReferralURL);
export default Memo;

/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const MessageBubbleDotsStacked = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M6.368 5.875V4h14.566v13.377l-1.84-1.841V5.875H6.367Zm11.223 1.5H3V20.75l3.663-3.663H17.59V7.374Zm-6.293 4.856a1.003 1.003 0 1 1-2.006 0 1.003 1.003 0 0 1 2.006 0Zm2.22 1.003a1.003 1.003 0 1 0 0-2.006 1.003 1.003 0 0 0 0 2.006Zm-5.442-1.003a1.003 1.003 0 1 1-2.006 0 1.003 1.003 0 0 1 2.006 0Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(MessageBubbleDotsStacked);
export default Memo;

/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const OfficeBuilding = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M4.499 2.998V21h4.875V13.5h5.25V21h4.875V2.998h-15Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(OfficeBuilding);
export default Memo;

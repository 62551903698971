import React, {
  ComponentProps,
  createContext,
  FC,
  ReactElement,
  ReactNode,
  useState,
  useContext,
} from 'react';

import { PicnicCss, styled } from '../../stitches.config';
import { DisplayNamed } from '../../storybook/utils';
import { Box } from '../Box';
import { IconButton } from '../Button';
import { Heading } from '../Heading';
import { Icon, IconName } from '../Icon';
import { Text } from '../Text';

const HeadingColorContext = createContext<ComponentProps<typeof Heading>['color'] | undefined>(
  undefined
);
const HeadingColorProvider = HeadingColorContext.Provider;
const useHeadingColor = () => {
  return useContext(HeadingColorContext);
};

const BannerRoot = styled('div', {
  display: 'flex',
  alignItems: 'center',
  padding: '$space3 $space4',
  borderRadius: '$radius2',
  border: '$borderWidths$borderWidth1 solid transparent',
  variants: {
    variant: {
      neutral: {
        backgroundColor: '$bgDefault',
        borderColor: '$borderLoud',
      },
      info: {
        backgroundColor: '$bgInformationalDefault',
      },
      warning: {
        backgroundColor: '$bgWarningDefault',
      },
      error: {
        backgroundColor: '$bgCriticalDefault',
      },
      success: {
        backgroundColor: '$bgSuccessDefault',
      },
      guidance: {
        backgroundColor: '$bgGuidanceDefault',
      },
    },
  },
  defaultVariants: {
    variant: 'info',
  },
});

const StyledImage = styled('img', {
  width: '$size12',
  margin: '0 $space7 0 $space3',
});

type Variant = 'neutral' | 'success' | 'warning' | 'error' | 'info' | 'guidance';

const iconDictionary: Record<Variant, IconName> = {
  neutral: 'CircleInformation',
  success: 'CircleCheckmark',
  warning: 'CircleExclamation',
  error: 'CircleError',
  info: 'CircleInformation',
  guidance: 'Lightbulb',
};

const Image: FC<React.PropsWithChildren<ComponentProps<typeof StyledImage>>> = ({
  role = 'presentation',
  ...rest
}) => {
  return <StyledImage role={role} {...rest} />;
};

type BannerHeadingType = Omit<ComponentProps<typeof Heading>, 'variant' | 'color'>;

const HeadingComponent: FC<React.PropsWithChildren<BannerHeadingType>> = ({
  children,
  css,
  ...rest
}) => {
  const resolvedColor = useHeadingColor();
  return (
    <Heading variant="sm" color={resolvedColor} css={{ mb: '$space1', ...css }} {...rest}>
      {children}
    </Heading>
  );
};

const TextComponent: FC<React.PropsWithChildren<ComponentProps<typeof Text>>> = ({
  children,
  css,
  ...rest
}) => {
  return (
    <Text {...rest} css={{ ...css }}>
      {children}
    </Text>
  );
};

const ActionComponent: FC<React.PropsWithChildren<ComponentProps<typeof Box>>> = ({
  children,
  css,
  ...rest
}) => {
  return (
    <Box
      css={{ display: 'flex', alignItems: 'center', ml: 'auto', pl: '$space4', ...css }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export interface BannerProps {
  variant?: 'error' | 'info' | 'warning' | 'success' | 'neutral' | 'guidance';
  dismissible?: boolean;
  onDismiss?: () => void;
  css?: PicnicCss;
  iconName?: IconName;
  role?: string;
}

/**
 * Banner component.
 */
const BannerComponent: FC<React.PropsWithChildren<BannerProps>> = ({
  children,
  variant = 'info',
  onDismiss,
  dismissible,
  iconName = iconDictionary[variant],
  css = {},
  role = 'status',
  ...rest
}) => {
  const [visible, setVisible] = useState(true);
  if (!visible) {
    return null;
  }
  const dismissBanner = () => {
    setVisible(false);
    if (onDismiss) {
      onDismiss();
    }
  };

  let image: ReactElement | null = null;
  let heading: ReactElement | null = null;
  let text: ReactElement | null = null;
  let action: ReactElement | null = null;
  const otherChildren: ReactNode[] = [];

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child) && child.type === Image) {
      image = child;
      return;
    }
    if (React.isValidElement(child) && child.type === HeadingComponent) {
      heading = child;
      return;
    }
    if (React.isValidElement(child) && child.type === TextComponent) {
      text = child;
      return;
    }
    if (React.isValidElement(child) && child.type === ActionComponent) {
      action = child;
      return;
    }
    otherChildren.push(child);
  });

  // All of the variant names match with header colors except error.
  const headingColor = variant === 'error' ? 'critical' : variant;

  return (
    <HeadingColorProvider value={headingColor as ComponentProps<typeof Heading>['color']}>
      <BannerRoot variant={variant} css={css} role={role} {...rest}>
        <Box css={{ display: 'flex', alignItems: image ? 'center' : 'flex-start', flexGrow: 1 }}>
          {image ? (
            image
          ) : (
            <Box as="span" css={{ mr: '$space2', lineHeight: '0' }}>
              <Icon name={iconName} size="medium" color={variant} />
            </Box>
          )}
          <Box css={{ display: 'flex', flexDirection: 'column' }}>
            {!!heading && heading}
            {!!text && text}
            {otherChildren.length > 0 && otherChildren}
          </Box>
        </Box>
        {!!action && action}
        {dismissible && (
          <IconButton
            description="Close banner"
            onClick={dismissBanner}
            iconName="X"
            size="small"
            css={{ ml: '$space4' }}
          />
        )}
      </BannerRoot>
    </HeadingColorProvider>
  );
};

type ComponentType = typeof BannerComponent;
export interface BannerCompositeComponent extends ComponentType {
  Image: typeof Image & DisplayNamed;
  Text: typeof TextComponent & DisplayNamed;
  Heading: typeof HeadingComponent & DisplayNamed;
  Action: typeof ActionComponent & DisplayNamed;
}
const Banner = BannerComponent as BannerCompositeComponent;
Banner.Image = Image;
Banner.Heading = HeadingComponent;
Banner.Text = TextComponent;
Banner.Action = ActionComponent;

Banner.displayName = 'Banner';
Banner.Image.displayName = 'Banner.Image';
Banner.Text.displayName = 'Banner.Text';
Banner.Heading.displayName = 'Banner.Heading';
Banner.Action.displayName = 'Banner.Action';

export { Banner };

/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Workflow = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M13.956 3.006v7.043h7.043V3.006h-7.043Zm0 10.957v7.043h7.043v-7.043h-7.043Zm-1.5-8.71H6.452v5.471H3v2.55h3.453v3.631h.001v1.854h6.002v-2.55H9.003V7.803h3.453v-2.55Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Workflow);
export default Memo;

/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const LaptopPage = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M19.504 5.252h-15v10.5h15v-10.5Zm-13.125 3.3h11.25v1.2H6.379v-1.2Zm11.25 2.7H6.379v1.2h11.25v-1.2Zm-15.567 6h19.875v1.502H2.062v-1.502Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(LaptopPage);
export default Memo;

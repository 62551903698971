/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const PaperAirplane = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M10.563 12.43 3.66 9.79 20.083 2.91l-9.52 9.52ZM11.593 13.46l2.64 6.903 6.88-16.423-9.52 9.52ZM9.528 13.467l1.028 1.028-5.36 5.36-1.028-1.03 5.36-5.358ZM6.438 12.957l1.028 1.029-2.778 2.779-1.029-1.029 2.779-2.779ZM10.037 16.557l1.03 1.029-2.78 2.778-1.028-1.028 2.778-2.779Z" />
  </svg>
);

const Memo = memo(PaperAirplane);
export default Memo;

/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Unsync = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M19.546 3.171 3.171 19.546l1.538 1.538L21.084 4.71l-1.538-1.538ZM8.48 10.993l-.873-.873-1.388 1.388a6.113 6.113 0 0 1 6.12-6.121c.533 0 1.05.07 1.543.203l.103.03.05.015 1.524-1.523-.068-.03a7.95 7.95 0 0 0-3.15-.654c-4.407-.082-8.08 3.59-8.08 8.08L2.874 10.12 2 10.993l3.24 3.24 3.24-3.24ZM16.268 12.75l3.24-3.24 3.242 3.242-.873.874-1.388-1.388c0 4.49-3.673 8.161-8.08 8.08a7.95 7.95 0 0 1-3.144-.652l-.063-.027 1.524-1.525.047.014c.031.01.062.018.093.026.494.133 1.01.204 1.543.204a6.113 6.113 0 0 0 6.12-6.121l-1.387 1.387-.873-.873Z" />
  </svg>
);

const Memo = memo(Unsync);
export default Memo;

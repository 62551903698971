/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SoupBowl = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="m13.012 11.808 7.013-6.013.976 1.138-5.913 5.07h5.898c0 4.969-4.026 8.997-8.993 8.997C7.026 21 3 16.972 3 12.002h8.392L14.046 3l1.438.424-2.472 8.384Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(SoupBowl);
export default Memo;

import React, { useState } from 'react';

import { styled } from '../../stitches.config';
import { Box } from '../Box';
import { Tag } from '../Tag';

import { MenuItems, MenuItem } from './types';

const PU_MIN_TOKEN_SIZE = 80;

const TagButtonPrimitive = styled('button', {
  color: '$textDefault',
  minHeight: '$size6',
  minWidth: PU_MIN_TOKEN_SIZE,
  margin: '$space1',
  cursor: 'pointer',
  display: 'inline-flex',
  padding: '0 $space2',
  backgroundColor: '$bgActionSecondary',
  alignItems: 'center',
  border: 'none',
  borderRadius: '$radiusMax',
  variants: {
    size: {
      small: { fontSize: '$fontSize2' },
      normal: { fontSize: '$fontSize3' },
    },
    disabled: {
      true: {
        color: '$textDisabled',
        cursor: 'not-allowed',
      },
      false: {
        '&:hover': { backgroundColor: '$bgActionSecondaryHover' },
        '&:active': { backgroundColor: '$bgActionSecondaryPressed' },
        focusVisible: '$focus',
      },
    },
  },
  defaultVariants: {
    size: 'normal',
  },
});

type TagGroupProps = {
  selectedItems: MenuItems;
  removeSelectedItem: (r: MenuItem) => void;
  wrapperWidth: number;
  maxTokens: number;
  size?: 'small' | 'normal';
  disabled?: boolean;
  errors?: boolean[] | string[];
};

export const TagGroup = ({
  selectedItems,
  removeSelectedItem,
  maxTokens = 10,
  size = 'normal',
  disabled = false,
  errors,
}: TagGroupProps) => {
  const [isShowingAllTags, setIsShowingAllTags] = useState(false);

  const remaining = selectedItems.length - maxTokens;
  const hasRemaining = remaining > 1;
  const showSingleToken = maxTokens === 1 && hasRemaining;
  const remainingText = showSingleToken
    ? `${selectedItems[0].label} +${remaining} more`
    : `+${remaining} more`;
  const tags =
    hasRemaining && !isShowingAllTags ? selectedItems.slice(0, maxTokens) : selectedItems;

  const handleToggleShowAllTags = () => {
    setIsShowingAllTags((prevState) => !prevState);
  };

  return (
    <Box as="span" css={{ display: 'flex', flexWrap: 'wrap' }}>
      {!showSingleToken &&
        tags.map((selectedItem: MenuItem, idx: number) => (
          <Tag
            size={size}
            key={selectedItem.value}
            onDelete={() => removeSelectedItem(selectedItem)}
            disabled={disabled}
            content={selectedItem.label}
            css={{ margin: '$space1' }}
            variant={!!errors && errors[idx] ? 'error' : 'default'}
          />
        ))}
      {hasRemaining && (
        <TagButtonPrimitive onClick={handleToggleShowAllTags} disabled={disabled}>
          {isShowingAllTags ? 'See less' : remainingText}
        </TagButtonPrimitive>
      )}
    </Box>
  );
};

/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const RichCard = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M19.5 21h-15V3h15v18ZM6 19.5h12v-15H6v15Z" />
    <path d="M16.5 13.5h-9V15h9v-1.5ZM16.5 16.5h-9V18h9v-1.5ZM18 10.5H6V12h12v-1.5Z" />
  </svg>
);

const Memo = memo(RichCard);
export default Memo;

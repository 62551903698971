/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Push = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M8.25 7h7.5v3h-7.5V7Z" />
    <path fillRule="evenodd" d="M6 21h12V3H6v18Zm10.5-1.5h-9v-15h9v15Z" clipRule="evenodd" />
  </svg>
);

const Memo = memo(Push);
export default Memo;

import { Environment } from 'react-relay';

import { API, AuthSession } from '@attentive/acore-utils';
import { AuthFlowStrategy } from '@attentive/data';

import {
  AuthClient,
  Connection,
  ConnectionType,
  VerifySessionInput,
  VerifySessionResponse,
} from './AuthClient';
import { commitRefreshSessionOrChallengeMutation } from './commitRefreshSessionOrChallengeMutation';
import { DEMO_EMAIL, Routes } from './constants';
import { logAuthError } from './errors';
import { buildLogoutRedirectUri, generateStateString, retryRequest } from './utils';

import {
  StandardErrorStatus,
  commitRefreshSessionOrChallengeMutation$data,
} from './__generated__/commitRefreshSessionOrChallengeMutation.graphql';

const CONNECTION_TYPE_URL = '/identity/connection-type';
const LOGIN_WITH_GOOGLE_TOKEN_URL = '/identity/login-with-google-token';
const LOGIN_WITH_SSO_URL = '/identity/login-with-sso/connections';
const DEFAULT_ERROR_MESSAGE = 'Could not verify user session';

interface Token {
  token: string;
  companyId: number;
  companyGqlId: string;
}

export class AttentiveAuthClient implements AuthClient {
  public async getConnection(email: string) {
    if (email === DEMO_EMAIL) {
      return {
        connectionName: 'credentials',
        connectionType: ConnectionType.CREDENTIALS as const,
        connectionId: null,
      };
    }
    try {
      return await retryRequest('get connection type', () =>
        // GMRU: GET /identity/connection-type
        API.get<Connection>(`${CONNECTION_TYPE_URL}?email=${encodeURIComponent(email)}`)
      );
    } catch (error) {
      logAuthError(error);
      throw error;
    }
  }

  public loginWithSso(
    connectionName: string,
    redirectPath: string,
    sneakPreviewCommitSha?: string,
    companyId?: string
  ) {
    const { protocol, host } = location;
    const domain = `${protocol}//${host}`;
    let apiBaseUrl = API.getAPIUrl();
    if (apiBaseUrl.endsWith('/')) {
      apiBaseUrl = apiBaseUrl.substring(0, apiBaseUrl.length - 1);
    }
    const ssoLoginUrl = `${apiBaseUrl}${LOGIN_WITH_SSO_URL}/${connectionName}`;
    const stateLength = 10;

    // We are setting a link between our random state and a redirect path. We are using AUTH_REDIRECT_METADATA
    // as a key and storing the state, redirect path and sneak peak commit sha in order to mitigate CSRF attacks
    const state = generateStateString(stateLength);
    sessionStorage.setItem(
      'AUTH_REDIRECT_METADATA',
      JSON.stringify({ state, redirectPath, sneakPreviewCommitSha, companyId })
    );

    const params = new URLSearchParams({
      state,
      redirectUri: domain + Routes.SsoSigninCallBack,
    });
    if (companyId) {
      params.append('companyId', companyId);
    }
    window.location.assign(`${ssoLoginUrl}?${params}`);
  }

  public processSsoCallback(token: string, redirectPath: string) {
    AuthSession.persistStrategy(AuthFlowStrategy.Internal);
    AuthSession.persistToken(AuthFlowStrategy.Internal, token);
    window.location.assign(redirectPath);
  }

  public async loginWithGoogleToken(googleToken: string, redirectPath: string) {
    try {
      const { token } = await retryRequest('log in with Google SSO', () =>
        // GMRU: POST /identity/login-with-google-token
        API.post<Token>(LOGIN_WITH_GOOGLE_TOKEN_URL, {
          token: googleToken,
        })
      );
      AuthSession.persistStrategy(AuthFlowStrategy.Internal);
      AuthSession.persistToken(AuthFlowStrategy.Internal, token);
      window.location.assign(redirectPath);
    } catch (error) {
      logAuthError(error);
      throw error;
    }
  }

  public verifySession(input: VerifySessionInput, relayEnvironment: Environment) {
    return new Promise<VerifySessionResponse>((res, rej) => {
      const handleComplete = (response: commitRefreshSessionOrChallengeMutation$data) => {
        const isReturningDefaultFailure =
          response.refreshSessionOrChallenge?.__typename === 'DefaultErrorFailure';

        if (!response.refreshSessionOrChallenge || isReturningDefaultFailure) {
          const message =
            response.refreshSessionOrChallenge?.message || 'Refresh session call failed';
          const error = new Error(message);

          const status: StandardErrorStatus =
            response.refreshSessionOrChallenge?.status || 'STANDARD_ERROR_STATUS_INTERNAL';

          if (
            status === 'STANDARD_ERROR_STATUS_UNAUTHENTICATED' ||
            status === 'STANDARD_ERROR_STATUS_PERMISSION_DENIED'
          ) {
            rej(error);
            return;
          }

          if (status === 'STANDARD_ERROR_STATUS_UNAVAILABLE') {
            // This is intended to silence errors where no authenticator has been configured.
            // TODO: detect that better
            rej(error);
            return;
          }
          logAuthError(error);
          rej(error);
          return;
        }

        const isReturningRefreshOrChallenge =
          response.refreshSessionOrChallenge.__typename === 'RefreshSessionOrChallengeSuccess';

        if (
          isReturningRefreshOrChallenge &&
          response.refreshSessionOrChallenge.response?.__typename === 'RefreshSessionResponse'
        ) {
          const { token, company } = response.refreshSessionOrChallenge.response;
          AuthSession.persistToken(AuthFlowStrategy.Internal, token);
          res({
            companyId: company.id,
            companyRestId: company.internalId,
            challengeString: undefined,
          });
        } else if (
          isReturningRefreshOrChallenge &&
          response.refreshSessionOrChallenge.response?.__typename === 'ChallengeResponse'
        ) {
          const { company, nonce } = response.refreshSessionOrChallenge.response;

          res({
            companyId: company.id,
            companyRestId: company.internalId,
            challengeString: nonce,
          });
        } else {
          const error = new Error('Unexpected response from refresh session call');
          logAuthError(error);
          rej(error);
        }
      };

      const handleError = (err: Error) => {
        logAuthError(err);
        rej(new Error(DEFAULT_ERROR_MESSAGE));
      };
      const getInputType = () => {
        if (input.companyId) {
          return { companyId: input.companyId };
        } else if (input.companyRestId) {
          return { companyId: input.companyRestId.toString() };
        } else if (input.companyExternalId) {
          return { externalCompanyId: input.companyExternalId };
        }
        return { companyId: '' };
      };

      commitRefreshSessionOrChallengeMutation(
        relayEnvironment,
        {
          input: {
            id: getInputType(),
          },
        },
        handleError,
        handleComplete
      );
    });
  }

  public logout(logoutReason: string | null) {
    AuthSession.clearStorage();
    window.location.assign(buildLogoutRedirectUri(window.location, logoutReason));
  }
}

export const attentiveAuthClient = new AttentiveAuthClient();

import React from 'react';

import { IconSvgProps } from '../iconProps';

const Icon = ({ title, ...props }: IconSvgProps) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      {title && <title>{title}</title>}
      <path
        d="M21.7948 18.8061C20.6619 21.5228 18.0057 21.9989 17.1377 21.9989C16.8828 21.9989 12.9133 22.2094 7.20707 16.7954C2.61485 12.4382 2.05374 7.75282 2.00485 6.86259C1.9573 5.99548 2.20552 3.82025 5.19707 2.20406C5.56774 2.00379 6.30262 1.9151 6.4924 2.10493C6.57662 2.18917 9.08751 6.32201 9.15351 6.45893C9.21951 6.59563 9.25262 6.73033 9.25262 6.86237C9.25262 7.05108 9.11818 7.28714 8.84906 7.57032C8.58018 7.85351 8.28773 8.11313 7.97151 8.34897C7.65529 8.58503 7.36284 8.83509 7.09373 9.09938C6.82485 9.36345 6.6904 9.58084 6.6904 9.75066C6.70862 10.1914 7.06974 11.7703 9.82062 14.188C12.5719 16.6064 13.8973 17.2877 14.0246 17.3348C14.1522 17.3819 14.2582 17.4053 14.3431 17.4053C14.5131 17.4053 14.7302 17.271 14.9944 17.0021C15.2584 16.7329 16.1466 15.5152 16.4297 15.2461C16.7128 14.9773 16.9488 14.8429 17.1377 14.8429C17.2697 14.8429 17.4042 14.8757 17.5408 14.9418C17.6777 15.0078 21.8148 17.4293 21.8939 17.5102C22.1086 17.7303 21.957 18.4171 21.7948 18.8061Z"
        fill="#1B1F23"
        fillOpacity="0.4"
      />
    </svg>
  );
};

export const IOSPhone = React.memo(Icon);

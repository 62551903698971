/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Target = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M12.01 19.2a7.2 7.2 0 1 0 0-14.4 7.2 7.2 0 0 0 0 14.4Zm0 1.8a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M12.01 15.605a3.605 3.605 0 1 0 0-7.21 3.605 3.605 0 0 0 0 7.21Zm0 1.8a5.405 5.405 0 1 0 0-10.81 5.405 5.405 0 0 0 0 10.81Z"
      clipRule="evenodd"
    />
    <path d="M13.8 12a1.8 1.8 0 1 1-3.6 0 1.8 1.8 0 0 1 3.6 0Z" />
  </svg>
);

const Memo = memo(Target);
export default Memo;

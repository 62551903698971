/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const MessageBubbleRCSStacked = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M6.367 5.875V4H20.93v13.373l-1.84-1.84V5.875H6.367Zm11.22 1.499H3v13.373l3.662-3.662h10.925V7.374Zm-6.554 6.77c-1.072 0-1.687-.738-1.687-1.673 0-.994.679-1.7 1.687-1.7.433 0 .775.132.989.273v.593a1.453 1.453 0 0 0-.907-.315c-.707 0-1.15.474-1.15 1.126 0 .657.443 1.14 1.154 1.14.365 0 .693-.137.912-.305v.57c-.26.186-.62.291-.998.291Zm-3.271-1.418 1.076 1.327h-.73L7 12.613v1.44h-.592v-3.191H7.72c.633 0 1.035.383 1.035.944 0 .533-.36.902-.994.92Zm-.16-1.354H7v.976h.538c.397 0 .598-.187.598-.497 0-.3-.187-.479-.534-.479Zm5.048 2.421c.323.242.702.351 1.076.351.697 0 1.13-.383 1.13-.97 0-.434-.237-.707-.807-.926l-.323-.128c-.297-.123-.452-.232-.452-.451 0-.237.178-.37.534-.37.355 0 .647.133.907.347v-.579a1.576 1.576 0 0 0-.953-.296c-.693 0-1.103.369-1.103.93 0 .424.237.706.743.898l.333.127c.374.16.479.26.479.47 0 .264-.178.415-.543.415-.387 0-.743-.164-1.021-.397v.58Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(MessageBubbleRCSStacked);
export default Memo;

/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const NewWindow = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M17.547 4.5h-5.251v2.254h3.92l-6.833 6.833 1.326 1.325 6.838-6.838V12h2.247V4.5h-2.247ZM10.046 6h-5.25V19.5h13.499v-5.25h-2.252v2.997H7.045V8.251h3V6Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(NewWindow);
export default Memo;

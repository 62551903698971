/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Shield = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M4.499 10.967c0 3.186 1.591 6.16 4.213 7.88l3.287 2.16 3.287-2.16c2.634-1.734 4.212-4.694 4.212-7.88V8.712H4.5v2.255Z"
      clipRule="evenodd"
    />
    <path d="M19.5 4.5H4.498v2.25h15V4.5Z" />
  </svg>
);

const Memo = memo(Shield);
export default Memo;
